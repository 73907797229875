const state_array = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe, Canada, Africa and Middle East",
  AP: "Armed Forces Pacific",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  MH: "Republic of Marshall Islands",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export { state_array };

const country_array = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AX: 'Åland Islands',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'Saint Barthélemy',
  BM: 'Bermuda',
  BN: 'Brunei Darussalam',
  BO: 'Bolivia, Plurinational State of',
  BQ: 'Bonaire, Sint Eustatius and Saba',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CC: 'Cocos (Keeling) Islands',
  CD: 'Congo, Democratic Republic of the',
  CF: 'Central African Republic',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: "Côte d'Ivoire",
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CW: 'Curaçao',
  CX: 'Christmas Island',
  CY: 'Cyprus',
  CZ: 'Czechia',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  EH: 'Western Sahara',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FK: 'Falkland Islands (Malvinas)',
  FM: 'Micronesia, Federated States of',
  FO: 'Faroe Islands',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom of Great Britain and Northern Ireland',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GG: 'Guernsey',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Equatorial Guinea',
  GR: 'Greece',
  GS: 'South Georgia and the South Sandwich Islands',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'Heard Island and McDonald Islands',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IM: 'Isle of Man',
  IN: 'India',
  IO: 'British Indian Ocean Territory',
  IQ: 'Iraq',
  IR: 'Iran, Islamic Republic of',
  IS: 'Iceland',
  IT: 'Italy',
  JE: 'Jersey',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KI: 'Kiribati',
  KM: 'Comoros',
  KN: 'Saint Kitts and Nevis',
  KP: "Korea, Democratic People's Republic of",
  KR: 'Korea, Republic of',
  KW: 'Kuwait',
  KY: 'Cayman Islands',
  KZ: 'Kazakhstan',
  LA: "Lao People's Democratic Republic",
  LB: 'Lebanon',
  LC: 'Saint Lucia',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova, Republic of',
  ME: 'Montenegro',
  MF: 'Saint Martin, (French part)',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'North Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Niue',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PM: 'Saint Pierre and Miquelon',
  PN: 'Pitcairn',
  PR: 'Puerto Rico',
  PS: 'Palestine, State of',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Réunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russian Federation',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SH: 'Saint Helena, Ascension and Tristan da Cunha',
  SI: 'Slovenia',
  SJ: 'Svalbard and Jan Mayen',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SS: 'South Sudan',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SX: 'Sint Maarten, (Dutch part)',
  SY: 'Syrian Arab Republic',
  SZ: 'Eswatini',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territories',
  TG: 'Togo',
  TH: 'Thailand',
  TJ: 'Tajikistan',
  TK: 'Tokelau',
  TL: 'Timor-Leste',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Türkiye',
  TT: 'Trinidad and Tobago',
  TV: 'Tuvalu',
  TW: 'Taiwan, Province of China',
  TZ: 'Tanzania, United Republic of',
  UA: 'Ukraine',
  UG: 'Uganda',
  UM: 'United States Minor Outlying Islands',
  US: 'United States of America',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Holy See',
  VC: 'Saint Vincent and the Grenadines',
  VE: 'Venezuela, Bolivarian Republic of',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  VN: 'Viet Nam',
  VU: 'Vanuatu',
  WF: 'Wallis and Futuna',
  WS: 'Samoa',
  YE: 'Yemen',
  YT: 'Mayotte',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
export { country_array };

const nonames = ["llc", "lc", "pllc", "pa", "corp", "inc"];
export { nonames };

export const KEY_ORDER_INFO = "orderInfo";
export const KEY_PAYMENT_INFO = "paymentInfo";
export const KEY_REFRESH = "isRefresh";
export const KEY_ORDER_AFTER_SUCCESS_PAYMENT = "oderInfoAfterSuccessPayment";
export const KEY_PRE_INC_ORDER_INFO = "preIncOrderInfo";
export const KEY_PRE_INC_ORDER_INFO_BACKUP = "preIncOrderInfoBackup";
export const KEY_INC_SUMMARY = "incSummary";
export const KEY_INC_SUMMARY_BACKUP = "incSummaryBackup";
export const KEY_UPSELL_ORDER_UPDATE = "upsellOrderUpdate";
export const KEY_STATE_LLC_UPSELL_PAGE = "infoUpSellPage";
export const KEY_TRADEMARK_UPSELL_INFO = "trademarkUpsellInfo";
export const KEY_TRADEMARK_UPSELL_ORDER_INFO = "trademarkUpsellOrderInfo";
export const KEY_UPSELL_BUCKET = "upsellBucket";

export const setItemLocalStorage = (key, value) => {
  if (!localStorage || !key) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemLocalStorage = (key) => {
  if (!localStorage || !key) {
    return;
  }

  const value = localStorage.getItem(key);
  return JSON.parse(value);
};

export const setItemSessionStorage = (key, value) => {
  if (!sessionStorage || !key) {
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getItemSessionStorage = (key) => {
  if (!sessionStorage || !key) {
    return;
  }

  const value = sessionStorage.getItem(key);
  return JSON.parse(value);
};

export const removeItemSessionStorage = (key) => {
  if (!sessionStorage || !key) {
    return;
  }

  sessionStorage.removeItem(key);
};

export const nameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][pP][aA]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);

export const llcNameRegExp = new RegExp(
  /(?!.*(?:[ ][pP][aA]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);

export const pscNameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))^[ a-zA-Z0-9&-]+$/
);

export const partnershipNameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);

export const LIMITED_STATES = ['MA', 'SC'];

export const isStateLimited = (stateCode) => LIMITED_STATES.includes(stateCode);

export const EXCLUDE_ANNUAL_COMPLIANCE_REPORT_STATES = ['AL', 'MO', 'AZ', 'NM', 'OH'];

export const isStateIncludeAnnual = (stateCode) => !EXCLUDE_ANNUAL_COMPLIANCE_REPORT_STATES.includes(stateCode);